import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import dimensions from "styles/dimensions";
import Layout from "components/Layout";
import ExternalCard from "components/ExternalCard";

const TechnicalWritingTitle = styled("h1")`
    margin-bottom: 1em;
`

const TechnicalWritingGrid = styled("div")`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2.5em;

    @media(max-width: 1050px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.5em;
    }

    @media(max-width: ${dimensions.maxwidthMobile}px) {
        grid-template-columns: 1fr;
        grid-gap: 2.5em;
    }
`

const TechnicalWriting = ({ externals, meta }) => (
    <>
        <Helmet
            title={`Technical Writing | Carolyn Stransky`}
            htmlAttributes={{ lang: "en" }}
            link={[
                {
                    rel: `canonical`,
                    href: `https://workwithcarolyn.com/technical-writing`
                }
            ]}
            meta={[
                {
                    name: `description`,
                    content: `Technical writing samples by Carolyn Stransky.`,
                },
                {
                    property: `og:title`,
                    content: `Technical Writing | Carolyn Stransky`,
                },
                {
                    property: `og:description`,
                    content: `Technical writing samples by Carolyn Stransky.`,
                },
                {
                    name: `og:image`,
                    content: `https://user-images.githubusercontent.com/26869552/80156613-a8c83a00-85c4-11ea-98a6-45ecad014bcd.png`,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary_large_image`,
                },
                {
                    name: `twitter:creator`,
                    content: meta.author,
                },
                {
                    name: `twitter:title`,
                    content: `Technical Writing | Carolyn Stransky`,
                },
                {
                    name: `twitter:description`,
                    content: `Technical writing samples by Carolyn Stransky.`,
                },
                {
                    name: `twitter:image`,
                    content: `https://user-images.githubusercontent.com/26869552/80156613-a8c83a00-85c4-11ea-98a6-45ecad014bcd.png`,
                }
            ].concat(meta)}
        />
        <Layout>
            <TechnicalWritingTitle>
                Selected technical writing
            </TechnicalWritingTitle>
            <TechnicalWritingGrid>
                {externals.map((external, i) => (
                    <ExternalCard
                        key={i}
                        category={external.node.external_category}
                        link_text={external.node.external_link_text}
                        title={external.node.external_title}
                        date={external.node.external_date}
                        description={external.node.external_preview_description}
                        link={external.node.external_link}
                        uid={external.node._meta.uid}
                    />
                ))}
            </TechnicalWritingGrid>
        </Layout>
    </>
);

export default ({ data }) => {
    const externals = data.prismic.allExternals.edges;
    const meta = data.site.siteMetadata;
    if (!externals) return null;

    return (
        <TechnicalWriting externals={externals} meta={meta}/>
    )
}

TechnicalWriting.propTypes = {
    externals: PropTypes.array.isRequired,
    meta: PropTypes.object.isRequired,
};


export const query = graphql`
    {
        prismic {
            allExternals(sortBy: external_date_DESC) {
                edges {
                    node {
                        external_title
                        external_date
                        external_category
                        external_link_text
                        external_preview_description
                        external_link {
                            ... on PRISMIC__ExternalLink {
                                _linkType
                                url
                            }
                        }
                        _meta {
                            uid
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                description
                author
            }
        }
    }
`